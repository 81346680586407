
















































import { addSchedulerSetting, getSchedulerSetting, updateSchedulerSetting } from '@/api/scheduler-setting';
import { getWeatherTypeList } from '@/api/weather-type';
import { ElForm } from 'element-ui/types/form';
import {
  Component,
  Vue,
  Prop,
  Watch,
} from 'vue-property-decorator';
import moment from 'moment';

@Component({
  name: 'CollectionSettingForm',
})
export default class extends Vue {
  @Prop({ required: true }) private selectedId!: number | null;

  @Watch('dateValues.startMonth')
  private handleChangeStartMonth(month: string) {
    const days = this.daysOfMonth(month);
    if (days.indexOf(this.dateValues.startDay) < 0) {
      this.dateValues.startDay = days[days.length - 1];
    }
  }

  @Watch('dateValues.endMonth')
  private handleChangeEndMonth(month: string) {
    const days = this.daysOfMonth(month);
    if (days.indexOf(this.dateValues.endDay) < 0) {
      this.dateValues.endDay = days[days.length - 1];
    }
  }

  mounted() {
    this.getWeatherTypeList();
    this.setForm();
  }

  get months() {
    return ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  }

  private formLoading = true;

  private weatherTypeLoading = true;

  private formData = {
    searchType: '',
    searchValue: '',
    startDate: '',
    endDate: '',
    useState: true,
  };

  private dateValues = {
    startMonth: '',
    startDay: '',
    endMonth: '',
    endDay: '',
  }

  private weatherTypes = [];

  private startDateValidator(rule: any, value: string, callback: Function) {
    if (!this.dateValues.startMonth) callback(new Error('수집 시작 월을 선택하세요.'));
    if (!this.dateValues.startDay) callback(new Error('수집 시작 일을 선택하세요.'));
    else callback();
  }

  private endDateValidator(rule: any, value: string, callback: Function) {
    if (!this.dateValues.endMonth) callback(new Error('수집 종료 월을 선택하세요.'));
    if (!this.dateValues.endDay) callback(new Error('수집 종료 일을 선택하세요.'));
    else callback();
  }

  private rules = {
    searchType: [
      { required: true, message: '기상영향을 선택하세요.', trigger: 'blur' },
    ],
    startDate: [
      { validator: this.startDateValidator, trigger: 'change' },
      { required: true, message: '수집 시작일을 입력하세요.', trigger: 'blur' },
    ],
    endDate: [
      { validator: this.endDateValidator, trigger: 'change' },
      { required: true, message: '수집 종료일을 입력하세요.', trigger: 'blur' },
    ],
  };

  private getWeatherTypeList() {
    getWeatherTypeList().then((res) => {
      this.weatherTypes = res.data;
      this.weatherTypeLoading = false;
    }).catch(() => {
      this.$message.error('기상영향 목록을 불러오는데 실패했습니다.');
      this.weatherTypeLoading = false;
    });
  }

  private setForm() {
    if (this.selectedId !== null) {
      getSchedulerSetting(this.selectedId).then((res) => {
        this.formLoading = false;
        this.formData = {
          ...this.formData,
          ...res.data,
        };
        this.dateValues = {
          startMonth: res.data.startDate.substr(0, 2),
          startDay: res.data.startDate.substr(2, 2),
          endMonth: res.data.endDate.substr(0, 2),
          endDay: res.data.endDate.substr(2, 2),
        };
      }).catch(() => {
        this.$message.error('자동수집 설정을 불러오는데 실패했습니다.');
        this.$emit('close');
      });
    } else {
      this.formLoading = false;
    }
  }

  private handleCancle() {
    this.$emit('close');
  }

  private handleSubmit() {
    this.covertDate();
    (this.$refs.schedulerForm as ElForm).validate((valid: boolean) => {
      if (valid) {
        if (this.selectedId === null) {
          addSchedulerSetting(this.formData).then(() => {
            this.$message.success('성공적으로 자동수집 설정을 추가했습니다.');
            this.$emit('save');
          }).catch(() => {
            this.$message.error('자동수집 설정 추가를 실패했습니다.');
          });
        } else {
          updateSchedulerSetting(this.selectedId, this.formData).then((res) => {
            this.$message.success('성공적으로 자동수집 설정을 수정했습니다.');
            this.$emit('update');
          }).catch(() => {
            this.$message.error('자동수집 설정 수정을 실패했습니다.');
          });
        }
      }
    });
  }

  private covertDate() {
    this.formData.startDate = `${this.dateValues.startMonth}${this.dateValues.startDay}`;
    this.formData.endDate = `${this.dateValues.endMonth}${this.dateValues.endDay}`;
  }

  private daysOfMonth(month: string) {
    if (month) return Array.from({ length: moment(`2020-${month}`).daysInMonth() }, (x, i) => moment().startOf('month').add(i, 'days').format('DD'));
    return [];
  }
}

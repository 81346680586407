import request from '@/util/request';

const PATH = '/weather-type';

export const getWeatherTypeList = () => request({
  url: PATH,
  method: 'get',
});

export const checkWeatherTypeName = (name: string) => request({
  url: `${PATH}/check-name/${name}`,
  method: 'get',
});

export const addWeatherType = (data: any) => request({
  url: PATH,
  method: 'post',
  data,
});

export const deleteWeatherType = (name: any) => request({
  url: `${PATH}/${name}`,
  method: 'delete',
});

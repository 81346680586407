



















import { getSchedulerTime, saveSchedulerTime } from '@/api/scheduler-time';
import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';
import { ElForm } from 'element-ui/types/form';

@Component({
  name: 'SchedulerTimeForm',
})
export default class extends Vue {
  mounted() {
    this.getSchedulerTime();
  }

  private formLoading = true;

  private form = {
    time: moment().format('HHmm'),
  }

  private rules = {
    time: [
      { required: true, message: '수집시작 시간을 입력하세요.', trigger: 'blur' },
    ],
  }

  private getSchedulerTime() {
    getSchedulerTime().then((res) => {
      this.form.time = res.data.time;
      this.formLoading = false;
    }).catch(() => {
      this.formLoading = false;
    });
  }

  private handleClose() {
    this.$emit('close');
  }

  private handleSave() {
    (this.$refs.timeForm as ElForm).validate((valid: boolean) => {
      if (valid) {
        saveSchedulerTime(this.form).then((res) => {
          this.$message.success('성공적으로 수집시작 시간을 저장했습니다.');
          this.$emit('save', res.data);
        }).catch(() => {
          this.$message.error('수집시작 시간 저장에 실패했습니다.');
          this.handleClose();
        });
      }
    });
  }
}



























































































import { Component, Vue } from 'vue-property-decorator';
import { deleteSchedulerSetting, getSchedulerSettingList } from '@/api/scheduler-setting';
import Pagination from '@/components/Pagination/index.vue';
import SchedulerForm from './components/SchedulerForm.vue';
import SchedulerTimeForm from './components/SchedulerTimeForm.vue';
import { getSchedulerTime } from '@/api/scheduler-time';
import moment from 'moment';

@Component({
  name: 'CollectionScheduler',
  components: {
    Pagination,
    SchedulerForm,
    SchedulerTimeForm,
  },
})
export default class extends Vue {
  mounted() {
    this.getSchedulerSettingList();
    this.getSchedulerTime();
  }

  private listLoading = true;

  private schedulerSettings = [];

  private listQuery = {
    page: 1,
    size: 10,
    type: 'searchType',
    value: '',
  };

  private totalElements = 0;

  private selectedId: number | null = null;

  private formVisible = false;

  private timeFormVisible = false;

  private schedulerTime = '오전 6시';

  private handleVisibleForm(id: number | null) {
    this.selectedId = id;
    this.formVisible = !this.formVisible;
  }

  private handleSearch() {
    this.listQuery.page = 1;
    this.getSchedulerSettingList();
  }

  private getSchedulerSettingList() {
    this.listLoading = true;
    getSchedulerSettingList(this.listQuery).then((res) => {
      if (this.listQuery.page > 1 && res.data.content.length === 0) {
        this.listQuery.page -= 1;
        this.getSchedulerSettingList();
      } else {
        this.listLoading = false;
        this.schedulerSettings = res.data.content;
        this.totalElements = res.data.totalElements;
      }
    }).catch((error) => {
      this.listLoading = false;
      this.$message.error(error.response.data);
    });
  }

  private getSchedulerTime() {
    getSchedulerTime().then((res) => {
      this.schedulerTime = moment(res.data.time, 'HHmm').format('LT');
    });
  }

  private handleChangePage(pageable: any) {
    this.listQuery.page = pageable.page;
    this.getSchedulerSettingList();
  }

  private handleSuccessSave() {
    this.handleVisibleForm(null);
    this.listQuery = {
      page: 1,
      size: 10,
      type: 'searchType',
      value: '',
    };
    this.getSchedulerSettingList();
  }

  private handleSuccessUpdate() {
    this.handleVisibleForm(null);
    this.getSchedulerSettingList();
  }

  private handleDelete(id: number) {
    this.$confirm('정말 자동수집 설정을 삭제하시겠습니까?', {
      type: 'warning',
      confirmButtonText: '예',
      cancelButtonText: '아니오',
    }).then(() => {
      deleteSchedulerSetting(id).then(() => {
        this.$message.success('성공적으로 자동수집 설정을 삭제했습니다.');
        this.getSchedulerSettingList();
      }).catch(() => {
        this.$message.error('자동수집 설정 삭제를 실패했습니다.');
      });
    });
  }

  private handleVisibleTimeForm() {
    this.timeFormVisible = !this.timeFormVisible;
  }

  private handleSaveTime(data: any) {
    this.timeFormVisible = !this.timeFormVisible;
    this.schedulerTime = moment(data.time, 'HHmm').format('LT');
  }
}

import request from '@/util/request';

const PATH = '/scheduler-time';

export const getSchedulerTime = () => request({
  url: PATH,
  method: 'get',
});

export const saveSchedulerTime = (data: any) => request({
  url: `${PATH}`,
  method: 'post',
  data,
});

import request from '@/util/request';

const PATH = '/scheduler-setting';

export const getSchedulerSettingList = (listQuery: any) => request({
  url: PATH,
  method: 'get',
  params: {
    ...listQuery,
    page: listQuery.page - 1,
  },
});

export const getSchedulerSetting = (id: number) => request({
  url: `${PATH}/${id}`,
  method: 'get',
});

export const addSchedulerSetting = (data: any) => request({
  url: `${PATH}`,
  method: 'post',
  data,
});

export const updateSchedulerSetting = (id: number, data: any) => request({
  url: `${PATH}/${id}`,
  method: 'put',
  data,
});

export const deleteSchedulerSetting = (id: number) => request({
  url: `${PATH}/${id}`,
  method: 'delete',
});
